var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('Menues'),_c('v-main',[_c('div',{staticClass:"text-left"},[_c('v-sheet',{staticClass:"py-5 px-5",attrs:{"color":"grey lighten-2"}},[_c('h3',[_vm._v("Affiliate Orders | "),_c('small',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.from,'DD.MM.YYYY'))+" - "+_vm._s(_vm._f("moment")(_vm.to,'DD.MM.YYYY'))+" ")])])])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"slide-y-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-15",attrs:{"loading":_vm.loading,"absolute":"","dark":"","fab":"","top":"","right":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.menu ? 'mdi-calendar' : 'mdi-calendar-search'))])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","max":new Date().toISOString().substr(0, 10)},on:{"change":_vm.changeRange},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),(_vm.statistics.affiliates)?_c('v-data-table',{attrs:{"items":_vm.affiliates,"item-key":"affiliate","expanded":_vm.expanded,"headers":_vm.headers,"show-expand":"","sort-by":"sum","sort-desc":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.campaigns",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.campaigns.length)+" ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.floatFormat(item.sum,2,'.'))+" € ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"elevation-0",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"my-3"},_vm._l((item.campaigns),function(entry){return _c('v-col',{key:entry.campaign,attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',[_c('v-chip',[_vm._v(" "+_vm._s(entry.campaign !== 'null' ? entry.campaign : 'N/A')+" ")])],1),_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("Bestellungen")]),_c('td',[_vm._v(_vm._s(entry.count))])]),_c('tr',[_c('th',[_vm._v("Brutto-Umsatz")]),_c('td',[_vm._v(_vm._s(_vm.floatFormat(entry.sum,2,'.'))+" €")])])])])],1)],1)}),1)],1)]}}],null,false,558702848)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }