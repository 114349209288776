<template>
  <v-app id="inspire">

    <Menues/>

    <v-main>
      <div class="text-left">
        <v-sheet color="grey lighten-2" class="py-5 px-5"><h3>Download Logs | <small>{{
            from|moment('DD.MM.YYYY')
          }}
          - {{ to|moment('DD.MM.YYYY') }}</small></h3></v-sheet>
      </div>

      <slide-up-down :active="isShowForm" :duration="300" name="formSlider">
        <v-sheet color="accent" class="py-5 px-5">
          <v-form class="selectRange" @submit.prevent="changeRange">

            <v-date-picker v-model="dates" range></v-date-picker>

            <v-text-field v-model="dateRangeText" label="Zeitraum" prepend-icon="mdi-calendar"
                          readonly></v-text-field>

            <v-btn type="submit" color="primary">Speichern</v-btn>
          </v-form>
        </v-sheet>
      </slide-up-down>

      <v-container fluid class="px-5">

        <v-row dense>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">Kostenlose Downloads</v-card-title>

              <v-card-subtitle>
                Anzahl der Kostenlosen Produkt-Downloads im angegebenen Zeitraum.
              </v-card-subtitle>

              <v-card-text>
                <div class="stat-total-highlight">
                  {{ totalFreeDownloads }}
                  <small>/ {{ totalDownloads }}</small>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">Dokumente Downloads</v-card-title>

              <v-card-subtitle>
                Anzahl der heruntergeladenen Dokumente im genannten Zeitraum.
              </v-card-subtitle>

              <v-card-text>
                <div class="stat-total-highlight">
                  {{ totalDocumentsDownloads }}
                  <small>/ {{ totalDownloads }}</small>
                </div>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">


              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn absolute dark fab top right color="primary" v-bind="attrs"
                         v-on="on">
                    <v-icon>{{ isShowForm ? 'mdi-calendar' : 'mdi-calendar-search' }}</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="dates" range :max="new Date().toISOString().substr(0, 10)"
                               @change="changeRange"></v-date-picker>
              </v-menu>

              <v-card-title class="headline">Download Verlauf</v-card-title>
              <v-sparkline
                  :value="stats.map(stat => {return stat.total })"
                  color="#fff"
                  line-width=".5"
                  padding="16"
              ></v-sparkline>
            </v-card>
          </v-col>
        </v-row>

        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field style="max-width: 250px"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Suchen"
                        single-line
                        hide-details
          ></v-text-field>
        </v-card-title>


        <v-data-table :headers="headers" :items="computedLogs" :sort-by="['totalDownloads']" :sort-desc="[true]"
                      :items-per-page="10" :search="search" :loading="loading"
                      loading-text="Daten werden geladen... Bitte warten">

          <template v-slot:item.articleName="{ item }">
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="item.thumbPath"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.articleName"></v-list-item-title>
                <v-list-item-subtitle v-text="item.articleProductNumber"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item.actions="{ item }">
            <span>
              <v-btn icon :href="'https://clv.de/' + item.articleSeoPath" target="_blank">
                <v-icon>
                  mdi-open-in-new
                </v-icon>
              </v-btn>
            </span>
          </template>

        </v-data-table>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menues from '@/components/layout/Menues';
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
import moment from 'moment-timezone'
import coverMixin from '@/mixins/coverMixin'
import articleDataMixin from "@/mixins/articleDataMixin";

const DownloadLogsRepository = RepositoryFactory.get('downloadLogs');
const ArticleCacheRepository = RepositoryFactory.get('articleCache');

export default {
  name: "DownloadLogs",
  components: {
    Menues,
  },
  props: {
    source: String,
  },
  mixins: [coverMixin, articleDataMixin],
  data() {
    return {
      search: '',
      logs: [],
      totalFreeDownloads: 0,
      totalDocumentsDownloads: 0,
      totalDownloads: 0,
      stats: [],
      articles: [],
      loading: true,
      isShowForm: false,
      headers: [
        {text: 'Name', value: 'articleName'},
        {text: 'ProductNumber', value: 'articleProductNumber', align: ' d-none'},
        {text: 'Freie Downloads', value: 'totalDownloads'},
        {text: 'Dokumente', value: 'totalDocuments'},
        {text: 'Downloads (gesamt)', value: 'total'},
        {text: 'Aktionen', value: 'actions', align: 'end'},
      ],
      menu: false,
      from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      dates: [],
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      const {data} = await DownloadLogsRepository.getStatsFree(this.from, this.to);
      this.logs = data;

      this.totalFreeDownloads = this.logs.reduce(function (prev, cur) {
        return prev + cur.totalDownloads;
      }, 0);

      this.totalDocumentsDownloads = this.logs.reduce(function (prev, cur) {
        return prev + cur.totalDocuments;
      }, 0);

      this.totalDownloads = this.logs.reduce(function (prev, cur) {
        return prev + cur.total;
      }, 0);

      this.loading = false;
    },
    async fetchStats() {
      const {data} = await DownloadLogsRepository.getStats();
      this.stats = data;
    },
    async updateArticleCache() {
      await ArticleCacheRepository.update();
      // this.fetchArticles();
    },
    changeRange: function () {
      if (this.dates[0] <= this.dates[1]) {
        this.from = this.dates[0];
        this.to = this.dates[1];
      } else {
        this.from = this.dates[1];
        this.to = this.dates[0];
      }
      this.fetch();
      this.menu = false;
    },
    log(item) {
      console.log(item)
    }
  },
  created() {
    this.dates = [this.from, this.to];
    this.fetch();
    // this.fetchArticles();
    this.fetchStats();
    this.$vuetify.theme.dark = false
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    computedLogs() {
      return this.logs.map(item => {
        let article = this.getProductData(item.article_id, 'sw_id', false)

        if (!article) {
          this.updateArticleCache();
        } else {
          return {
            ...item,
            articleName: article.name,
            articleProductNumber: article.productNumber,
            articleSeoPath: article.seoPath,
            thumbPath: this.getThumbPath(article.coverPath),
          }
        }
      })
    }
  },
}
</script>

<style scoped>
.stat-total-highlight {
  margin: 20px 0;
  font-size: 50px;
}

.stat-total-highlight small {
  font-size: 15px;
}
</style>
