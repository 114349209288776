<template>
    <v-app id="inspire">

        <Menues/>

        <v-main>
            <div class="text-left">
                <v-sheet color="grey lighten-2" class="py-5 px-5">
                    <v-btn class="header-back-icon" icon :to="'/catalogs/'">
                        <v-icon>
                            mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <h3>Katalog: {{ catalog.title }}</h3>
                </v-sheet>
            </div>
            <slide-up-down :active="isShowForm" :duration="300" name="formSlider">
                <v-sheet v-if="isShowForm" color="grey lighten-4" class="py-5 px-5">
                    <v-form class="addArticle" @submit.prevent="addArticle">

                        <v-autocomplete
                                v-model="newArticle"
                                v-bind:items="computedArticles"
                                label="Artikel wählen"
                                filled
                                ref="article"
                                :item-text="item => item.productNumber + ' - ' + item.name"
                                item-value="sw_id"
                        >
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.productNumber"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>

                        <v-btn type="submit" color="primary">Hinzufügen</v-btn>
                        <v-btn text @click="isShowForm = !isShowForm">Abbrechen</v-btn>
                    </v-form>
                </v-sheet>
            </slide-up-down>
            <v-container fluid>
                <v-row dense>
                    <v-col cols="12" class="d-flex flex-column">
                        <v-skeleton-loader v-if="loading"
                                           width="100%"
                                           class="mx-auto"
                                           type="card"
                        ></v-skeleton-loader>
                        <v-card v-if="!loading" color="#385F73" dark class="flex d-flex flex-column">


                            <v-btn absolute dark fab top right color="primary" @click="isShowForm = !isShowForm">
                                <v-icon>{{ !isShowForm ? 'mdi-plus':'mdi-close'}}</v-icon>
                            </v-btn>

                            <v-card-title class="headline">{{ catalog.title }} ({{ articlesInCatalog.length }}
                                Artikel)
                            </v-card-title>

                            <v-card-subtitle>
                                {{ catalog.date | moment("DD.MM.YYYY") }}<br>
                            </v-card-subtitle>

                            <v-card-text>
                                {{ catalogDescription }}
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn color="light" outlined @click="isShowForm = !isShowForm">
                                    <v-icon left>mdi-plus</v-icon>
                                    Produkt hinzufügen
                                </v-btn>

                                <v-btn color="light" outlined
                                       :href="'https://api.clv.de/get_catalog_pdf/?catalog=' + currentCatalogId">
                                    <v-icon left>mdi-download</v-icon>
                                    Download
                                </v-btn>

                                <v-btn color="light" outlined disabled>
                                    <v-icon left>mdi-delete</v-icon>
                                    Löschen
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>

                <v-data-table :headers=tableHeader :items="indexedCatalog" :items-per-page="10" :search="search"
                              :loading="loading" loading-text="Daten werden geladen... Bitte warten"
                              :sort-by="['order']" :sort-desc="[false]" class="my-5"
                              item-key="id">
                    <template v-slot:item.product.article_id="{ item }">
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-img :src="item.thumbPath"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-text="item.articleName"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.articleProductNumber"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-slot:item.order="{ item }">
                        <v-btn icon :disabled="item.order === minPosition" @click="changePosition(item, 'increase')">
                            <v-icon>
                                mdi-chevron-up
                            </v-icon>
                        </v-btn>

                        {{ item.order }}

                        <v-btn icon :disabled="item.order === maxPosition" @click="changePosition(item, 'decrease')">
                            <v-icon>
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>

                    </template>
                    <template v-slot:item.actions="{ item }">
                        <span>
                        <v-btn icon :href="'https://clv.de/' + item.articleSeoPath" target="_blank">
                            <v-icon>
                                mdi-open-in-new
                            </v-icon>
                        </v-btn>

                        <v-btn icon @click="removeArticle(item.product.article_id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                            </span>
                    </template>
                </v-data-table>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Menues from '@/components/layout/Menues'
    import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
    import coverMixin from '@/mixins/coverMixin'

    const CatalogsRepository = RepositoryFactory.get('catalogs');
    const ArticleCacheRepository = RepositoryFactory.get('articleCache');

    export default {
        name: "Katalog",
        components: {
            Menues,
        },
        props: {
            source: String,
        },
        mixins: [coverMixin],
        data() {
            return {
                isShowForm: false,
                search: '',
                catalog: [],
                indexedCatalog: [],
                catalogDescription: 'Es liegt keine Beschreibung/Notiz vor.',
                articles: [],
                newArticle: "",
                loading: true,
                articlesInCatalog: '',
                currentCatalogId: this.$route.params.id,
                maxPosition: 0,
                minPosition: 0,
                tableHeader: [
                    {text: 'Artikel', value: 'product.article_id', sortable: false},
                    {text: 'Position', value: 'order', sortable: false},
                    {text: 'Aktionen', value: 'actions', align: 'end', sortable: false},
                ]
            }
        },
        methods: {
            async fetch() {
                const {data} = await CatalogsRepository.getByID(this.currentCatalogId);
                this.catalog = data;
                this.indexedCatalog = this.indexItems(this.catalog.products);
                if (this.catalog.description) {
                    this.catalogDescription = this.catalog.description;
                }
                this.createArticleList();
                this.maxPosition = Math.max.apply(Math, this.catalog.products.map(function (o) {
                    return o.order;
                }));
                this.minPosition = Math.min.apply(Math, this.catalog.products.map(function (o) {
                    return o.order;
                }));
                this.loading = false;
            },
            async fetchArticles() {
                this.loading = true;
                const {data} = await ArticleCacheRepository.get();
                this.articles = data;
                this.fetch();
            },
            createArticleList: function () {
                this.articlesInCatalog = this.catalog.products.map(productEntry => {
                    return productEntry.product.article_id;
                });
            },
            addArticle: function () {
                if (this.newArticle !== '') {
                    CatalogsRepository.addArticle(this.currentCatalogId, this.newArticle)
                        .then(response => {
                            if (response.data.success === true) {
                                this.fetch();
                                let newArticle = this.articles.find(obj => { return obj.sw_id === this.newArticle; });
                                this.$store.dispatch('setMessage', 'Der Artikel "' + newArticle.name + '" wurde hinzugefügt.');
                                this.newArticle = '';
                                this.$refs.article.focus();
                            } else {
                                // ERROR
                                console.log(response.data.message);
                                this.$store.dispatch('setMessage', 'Der Artikel konnte nicht hinzugefügt werden.');
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            this.$store.dispatch('setMessage', 'Der Artikel konnte nicht hinzugefügt werden.');
                        })
                }
            },
            removeArticle: function (article_id) {
                CatalogsRepository.removeArticle(this.currentCatalogId, article_id)
                    .then(response => {
                        if (response.data.success === true) {
                            this.fetch();
                        } else {
                            // ERROR
                            console.log(response.data.message)
                        }
                    })
                    .catch(err => console.log(err))
            },
            changeOrder: function (article_id, new_order) {
                CatalogsRepository.changeOrder(this.currentCatalogId, article_id, new_order)
                    .then(response => {
                            console.log(response.data.message)

                    })
                    .catch(err => console.log(err))
            },
            changePosition: function (item, direction) {
                let targetIndex = (direction === 'increase') ? item.index - 1 : item.index + 1;
                let targetItem = this.indexedCatalog.find(obj => {
                    return obj.index === targetIndex;
                });
                let currentOrder = item.order;
                let currentIndex = item.index;
                let newOrder = targetItem.order;
                let newIndex = targetItem.index;
                this.indexedCatalog[currentIndex].order = newOrder;
                this.indexedCatalog[newIndex].order = currentOrder;
                this.indexedCatalog[currentIndex].index = newIndex;
                this.indexedCatalog[newIndex].index = currentIndex;
                this.indexedCatalog.sort((a, b) => (a.order > b.order) ? 1 : -1);

                this.changeOrder(item.product.article_id, newOrder);
                this.changeOrder(targetItem.product.article_id, currentOrder);
            },
            indexItems(items) {
                return items.map((item, index) => {
                  let article = this.articles.find(obj => { return obj.sw_id === item.product.article_id; });
                    if (article === undefined) {
                        this.updateArticleCache();
                    } else {
                        return {
                            index: index,
                            ...item,
                            articleName: article.name,
                            articleProductNumber: article.productNumber,
                            articleSeoPath: article.seoPath,
                            thumbPath: this.getThumbPath(article.coverPath)
                        }
                    }
                })
            },
            async updateArticleCache() {
                await ArticleCacheRepository.update();
            },
        },
        created() {
            this.fetchArticles();
            this.$vuetify.theme.dark = false
        },
        computed: {
            computedArticles() {
                return this.articles.map(item => {
                    return {
                        ...item,
                        disabled: this.articlesInCatalog.includes(item.sw_id)
                    }
                })
            }
        }
    }
</script>

<style>
    .header-back-icon {
        float: left;
        margin-top: -3px;
        margin-right: 10px;
    }
</style>
