import usersRepository from "./usersRepository"
import catalogsRepository from "./catalogsRepository";
import promotionOrderRepository from "./promotionOrderRepository"
import downloadLogsRepository from "./downloadLogsRepository"
import configRepository from "./configRepository";
import articleCacheRepository from "./articleCacheRepository";
import newslettersRepository from "./newslettersRepository";
import marketingRepository from "./marketingRepository";
import revenueRepository from "./revenueRepository";

const repositories = {
    users: usersRepository,
    catalogs: catalogsRepository,
    promotionOrders: promotionOrderRepository,
    downloadLogs: downloadLogsRepository,
    config: configRepository,
    articleCache: articleCacheRepository,
    newsletters: newslettersRepository,
    marketing: marketingRepository,
    revenue: revenueRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};