export default  {
    methods: {
      getThumbPath(path){
        if(path != ''){
            let lastPeriodInPath = path.lastIndexOf('.');
            let tempPath = path.substring(0, lastPeriodInPath)+'_400x400'+path.substring(lastPeriodInPath);
            let mediaPathString = '/media/'
            let mediaPartOfPath = tempPath.indexOf(mediaPathString);
            return tempPath.substring(0, mediaPartOfPath)+'/thumbnail/'+tempPath.substring(mediaPartOfPath + mediaPathString.length);
        }else{
            return path;
        }
      },
    }
  }