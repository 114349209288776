export default  {
  methods: {
    getProductData(index, indexName, propName) {
      let article = this.$store.state.articleCache.find( a => a[indexName] === index)
      if (!article)
        return false
      if (propName)
        return article[propName]
      return article
    },
  },
}
