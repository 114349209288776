<template>
    <v-app id="inspire">

        <Menues/>

        <v-main>
            <div class="text-left">
                <v-sheet color="grey lighten-2" class="py-5 px-5"><h3>Kataloge</h3></v-sheet>
            </div>
            <slide-up-down :active="isShowForm" :duration="300" name="formSlider">
                <v-sheet v-if="isShowForm" color="grey lighten-4" class="py-5 px-5">
                    <v-form class="addCatalog" @submit.prevent="addCatalog">
                        <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            v-model="newCatalogName"
                            label="Titel"
                            filled
                            placeholder="Name des Katalogs"
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                        <v-textarea
                            v-model="newCatalogDescr"
                            name="input-7-1"
                            filled
                            label="Beschreibung"
                        ></v-textarea>
                    </v-col>
                        <v-btn type="submit" color="primary">Hinzufügen</v-btn>
                        <v-btn text @click="isShowForm = !isShowForm">Abbrechen</v-btn>
                    </v-form>
                </v-sheet>
            </slide-up-down>
            <v-card>
                <v-btn absolute dark fab top right color="primary" @click="isShowForm = !isShowForm">
                <v-icon>{{ !isShowForm ? 'mdi-plus':'mdi-close'}}</v-icon>
            </v-btn>
            </v-card>
            <v-container fluid class="position: relative">
                <template>

                    <v-data-table :headers="headers" :items="catalogs" :items-per-page="25" :sort-by="['date']"
                                  :sort-desc="[true]" class="my-5">
                        <template v-slot:item.date="{ item }">
                            {{ item.date | moment("DD.MM.YYYY") }}
                        </template>
                        <template v-slot:item.products="{ item }">
                            {{ item.products.length }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon :href="'https://api.clv.de/get_catalog_pdf/?catalog=' + item.id">
                                <v-icon>
                                    mdi-download
                                </v-icon>
                            </v-btn>
                            <v-btn icon :to="'/catalog/' + item.id + '/'">
                                <v-icon>
                                    mdi-table-edit
                                </v-icon>
                            </v-btn>
                            <v-btn icon @click="deleteCatalog(item.id)">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </template>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Menues from '@/components/layout/Menues'
    import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";

    const CatalogsRepository = RepositoryFactory.get('catalogs');

    export default {
        name: "Kataloge",
        components: {
            Menues,
        },
        data() {
            return {
                isShowForm: false,
                search: '',
                catalogs: [],
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Titel', value: 'title'},
                    {text: 'Beschreibung', value: 'description'},
                    {text: 'Produktanzahl', value: 'products'},
                    {text: 'Date', value: 'date'},
                    {text: 'Aktionen', value: 'actions', align: 'end'},
                ],
                loading: true,
                newCatalogName: '',
                newCatalogDescr: '',
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await CatalogsRepository.get();
                this.catalogs = data;
                this.loading = false;
            },
            addCatalog: function () {
                if (this.newCatalogName !== '') {
                    CatalogsRepository.addCatalog(this.newCatalogName, this.newCatalogDescr)
                        .then(response => {
                            this.catalogs.push(response.data);
                            this.isShowForm = false;
                            this.$store.dispatch('setMessage', 'Der Katalog "' + this.newCatalogName + '" wurde erfolgreich hinzugefügt.');
                            this.newCatalogName = '';
                            this.newCatalogDescr = '';
                        })
                        .catch(
                            err => {
                                console.log(err);
                                this.$store.dispatch('setMessage', 'Katalog konnt nicht hinzugefügt werden.');
                            }
                        )
                }
            },
            deleteCatalog(catalogId) {
              CatalogsRepository.removeCatalog(catalogId)
                  .then(response => {
                    this.catalogs.push(response.data);
                    this.$store.dispatch('setMessage', 'Der Katalog wurde erfolgreich gelöscht.');
                    this.fetch()
                  })
                  .catch(
                      err => {
                        console.log(err);
                        this.$store.dispatch('setMessage', 'Katalog konnt nicht gelöscht werden.');
                      }
                  )
            },
        },
        created() {
            this.fetch();
            this.$vuetify.theme.dark = false
        },
    }
</script>
