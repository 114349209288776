import Repository from "./Repository";

const resource = "/intranet/article_cache";
const update_resource = "/intranet/update_article_cache";
const asset_update_forced_resource = "/intranet/force_full_asset_update";
const update_feeds_resource = "/intranet/update_feeds";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    getByID(id){
        return Repository.get(`${resource}/`+id+'/');
    },
    update(){
        return Repository.get(`${update_resource}/`);
    },
    forceFullAssetUpdate(id) {
        return Repository.get(`${asset_update_forced_resource}/?asset=${id}`);
    },
    updateFeeds() {
        return Repository.get(`${update_feeds_resource}/`);
    }
}