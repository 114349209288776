<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dens>

        <v-list-item v-for="item in items" :key="item.text" link :to="item.path">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2" v-if="isLoggedIn" @click="logout">
          <v-btn block color="light" elevation="0">Abmelden</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dense>

      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="mr-12 align-center">
        <span class="title">CLV Intranet</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="light" dark v-bind="attrs" v-on="on" text>
            {{ username }}
            <v-icon light right>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="isLoggedIn"
            @click="updateCache"
            :disabled="updatingCache">
            <v-progress-circular
                v-if="updatingCache"
                class="mr-2"
                indeterminate
                color="primary"
            />

            <v-icon
              v-else
              light
              left
            >
              mdi-update
            </v-icon>
            <v-list-item-title>Update Cache</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isLoggedIn"
            @click="updateFeeds"
            :disabled="updatingFeeds"
          >
            <v-progress-circular
                v-if="updatingFeeds"
                indeterminate
                color="primary"
            />
            <v-icon v-else light left>mdi-update</v-icon>

            <v-list-item-title>Update Feeds</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLoggedIn" @click="logout">
            <v-icon light left>mdi-lock</v-icon>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
  </div>
</template>

<script>
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";

const ArticleCacheRepository = RepositoryFactory.get('articleCache');

export default {
  name: "Menues",
  data() {
    return {
      drawer: null,
      search: '',
      username: this.$store.state.username,
      updatingCache: false,
      updatingFeeds: false,
      items: [
        {icon: 'mdi-view-dashboard', text: 'Übersicht', path: '/'},
        {icon: 'mdi-book-settings', text: 'Produkte', path: '/products/list'},
        {icon: 'mdi-download', text: 'Downloads', path: '/downloads'},
        {icon: 'mdi-book-open-variant', text: 'Kataloge', path: '/catalogs'},
        {icon: 'mdi-tag-multiple', text: 'Aktionen', path: '/promotions/orders'},
        {icon: 'mdi-tag-multiple', text: 'Affiliates', path: '/affiliates/orders'},
        {icon: 'mdi-chart-line', text: 'Umsätze', path: '/revenues'},
        {icon: 'mdi-email-multiple', text: 'Newsletters', path: '/newsletters'},
        {icon: 'mdi-application-cog', text: 'CLeVer', path: '/clever'},
      ],
      entries: [
        {title: 'Profil'},
        {title: 'Meine Beiträge'},
        {title: 'Einstellungen'},
      ],
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$store.dispatch('setMessage', 'Sie wurden erfolgreich abgemeldet.');
            this.$router.push('/login');
          })
    },
    async updateCache() {
      this.updatingCache = true
      await ArticleCacheRepository.update();
      await this.$store.dispatch('getArticleCache');
      await this.$store.dispatch('setMessage', 'Der Artikel Cache wurde aktualisiert.');
      this.updatingCache = false
    },
    async updateFeeds() {
      this.updatingFeeds = true
      await ArticleCacheRepository.updateFeeds();
      await this.$store.dispatch('setMessage', 'Die Feeds wurden aktualisiert.');
      this.updatingFeeds = false
    }
  },
}
</script>

<style>
.v-toolbar__content {
  color: white;
}

.v-toolbar__content .v-btn--outlined .v-btn__content .v-icon,
.v-toolbar__content .v-btn--round .v-btn__content .v-icon {
  color: white;
}

.stat-total-highlight {
  margin: 20px 0;
  font-size: 50px;
}

.stat-total-highlight small {
  font-size: 15px;
}
</style>
