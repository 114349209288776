import Repository from "./Repository";

const revenueResource = "/intranet/sw_revenue_stats";

export default {
    get(from, to, min_amount=false, max_amount=false, ref_id=false, group_no=false){
        let params = {
            from: from,
            to: to,
        };
        if(min_amount) params.min_amount = min_amount
        if(max_amount) params.max_amount = max_amount
        if(ref_id) params.ref_id = ref_id
        if(group_no) params.group = group_no
        return Repository.get(`${revenueResource}/`, { params });
    }
}