<template>
  <v-card elevation="0">
    <v-toolbar class="elevation-0 rounded-0 grey lighten-3">
      <v-toolbar-title class="black--text">Newsletter Statistik</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              elevation="0"
              v-bind="attrs"
              v-on="on"
              @click="updateNewsletterStats"
              :loading="loading"
          >
            <v-icon left>mdi-reload</v-icon>
            Update
          </v-btn>
        </template>
        <span>Daten aktualisieren</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
          <v-card color="#385F73" dark class="flex d-flex flex-column">
            <v-card-title class="headline">Umsatzübersicht</v-card-title>

            <v-card-subtitle>
              Summierter und durchschnittlicher direkter Umsatz aller Newsletter. Umsatz der direkt aus einem Klick
              im Newsletter resultiert ist.
            </v-card-subtitle>

            <v-card-text>
              <div class="stat-total-highlight">
                {{ floatFormat(directSalesSum, 2, '.') }}€
                <small> Ø {{
                    relevantCampaigns.length > 0 ? floatFormat(directSalesSum / relevantCampaigns.length, 2, '.') : 0
                  }}€</small>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
          <v-card color="#385F73" dark class="flex d-flex flex-column">
            <v-card-title class="headline">Downloadübersicht</v-card-title>

            <v-card-subtitle>
              Summierte und durchschnittliche Anzahl der Downloads aller Newsletter Empfänger. Downloads, welche
              direkt aus einem Klick im Newsletter resultieren.
            </v-card-subtitle>

            <v-card-text>
              <div class="stat-total-highlight">
                {{ totalDownloadCount }}
                <small> Ø {{
                    relevantCampaigns.length > 0 ? floatFormat(totalDownloadCount / relevantCampaigns.length, 0, '.') : 0
                  }}</small>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
          <v-card color="#385F73" dark class="flex d-flex flex-column">
            <v-card-title class="headline">Newsletter Abonnenten</v-card-title>

            <v-card-subtitle>
              Anzahl der aktuellen Abonnenten und der durchschnittliche Empfängerkreis aller Newsletter.
            </v-card-subtitle>

            <v-card-text>
              <div class="stat-total-highlight">
                {{ relevantCampaigns.length > 0 ? floatFormat(currentReceivers, 0, '.') : 0 }}
                <small> Ø {{ relevantCampaigns.length > 0 ? floatFormat(averageReceivers, 0, '.') : 0 }}</small>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-data-table
        :headers="headers"
        :items="relevantCampaigns"
        :items-per-page="25"
        :sort-by="['mj_campaign_data.date']"
        :sort-desc="[true]"
        class="my-5"
        item-key="id"
    >
      <template v-slot:header>
        <DialogLinkStatistik :dialog="dialog" :campaign="campaign" @close="closeDetails"/>
      </template>

      <template v-slot:item.mj_campaign_data.date="{ item }">
        {{ item.mj_campaign_data.date | moment("DD.MM.YYYY") }}
      </template>

      <template v-slot:item.mj_campaign_data.count_delivered="{ item }">
        <ProgressBar
            :percentage="(item.mj_campaign_data.count_delivered / maxCountDelivered) * 100"
            :label="`${floatFormat(item.mj_campaign_data.count_delivered,0,'.')}`"
            :height="25"
        />
      </template>

      <template v-slot:item.recipient_order_total="{ item }">
        <ProgressBar
            v-if="item.recipient_order_total !== null"
            :percentage="(item.recipient_order_total / maxReceiverSalesSum) * 100"
            :label="`${floatFormat(item.recipient_order_total,2,'.')}€`"
            :postfix="`(${item.recipient_order_count})`"
            :height="25"
        />
        <ProgressBar
            v-else
            :percentage="0"
            label="0,00€"
            postfix="(0)"
            :height="25"
        />
      </template>

      <template v-slot:item.mj_campaign_data.count_opened="{ item }">
        <ProgressBar
            :percentage="(item.mj_campaign_data.count_opened / maxCountOpened) * 100"
            :label="`${floatFormat((item.mj_campaign_data.count_opened / item.mj_campaign_data.count_delivered) * 100,2)}%`"
            :postfix="`(${floatFormat(item.mj_campaign_data.count_opened,0,'.')})`"
            :height="25"
        />
      </template>

      <template v-slot:item.mj_campaign_data.count_clicked="{ item }">
        <ProgressBar
            :percentage="(item.mj_campaign_data.count_clicked / maxCountClicked) * 100"
            :label="`${floatFormat((item.mj_campaign_data.count_clicked / item.mj_campaign_data.count_opened) * 100,2)}%`"
            :postfix="`(${floatFormat(item.mj_campaign_data.count_clicked,0,'.')})`"
            :height="25"
        />
      </template>

      <template v-slot:item.clickRevenue="{ item }">
        <ProgressBar
            v-if="item.clickRevenue > 0"
            :percentage="(item.clickRevenue / maxDirectSalesSum) * 100"
            :label="`${floatFormat(item.clickRevenue,2,'.')}€`"
            :postfix="`(${item.mj_campaign_data.order_data[0].count_orders})`"
            :height="25"
        />
        <ProgressBar
            v-else
            :percentage="0"
            label="0,00€"
            postfix="(0)"
            :height="25"
        />
      </template>

      <template v-slot:item.downloads="{ item }">
        <ProgressBar
            v-if="downloadLogs[item.code]"
            :percentage="(item.downloads / maxDownloadCount) * 100"
            :label="item.downloads"
            :height="25"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="openDetails(item)">
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
import FormatterMixin from "@/mixins/FormatterMixin";
import DialogLinkStatistik from "@/components/newsletter/DialogLinkStatistic";
import ProgressBar from "@/components/elements/ProgressBar";

const NewslettersRepository = RepositoryFactory.get('newsletters');
const DownloadLogsRepository = RepositoryFactory.get('downloadLogs');

export default {
  name: "NewslettersCampaignStatistics",
  components: {
    DialogLinkStatistik,
    ProgressBar,
  },
  mixins: [FormatterMixin],
  data() {
    return {
      isShowForm: false,
      search: '',
      campaigns: [],
      headers: [
        {text: 'Datum', value: 'mj_campaign_data.date'},
        {text: 'Titel', value: 'mj_campaign_data.subject'},
        {text: 'Empfänger', value: 'mj_campaign_data.count_delivered'},
        {text: 'Empfänger-Umsatz', value: 'recipient_order_total'},
        {text: 'Öffnungsrate', value: 'mj_campaign_data.count_opened'},
        {text: 'Klickrate', value: 'mj_campaign_data.count_clicked'},
        {text: 'Klickumsatz', value: 'clickRevenue'},
        {text: 'Downloads', value: 'downloads'},
        {text: 'Aktionen', value: 'actions', align: 'end'},
      ],
      loading: true,
      newCatalogName: '',
      newCatalogDescr: '',
      dialog: false,
      campaign: null,
      downloadLogs: [],
      totalDownloadCount: 0,
      tab: null,
      items: [
        'Statistik', 'Kampagnen'
      ],
    }
  },
  computed: {
    maxCountDelivered() {
      return Math.max.apply(Math, this.relevantCampaigns.map(function (c) {
        return c.mj_campaign_data.count_delivered;
      }))
    },
    maxCountOpened() {
      return Math.max.apply(Math, this.relevantCampaigns.map(function (c) {
        return c.mj_campaign_data.count_opened;
      }))
    },
    maxCountClicked() {
      return Math.max.apply(Math, this.relevantCampaigns.map(function (c) {
        return c.mj_campaign_data.count_clicked;
      }))
    },
    maxDirectSalesSum() {
      return Math.max.apply(Math, this.relevantCampaigns.map(function (c) {
        return !c.mj_campaign_data.order_data[0] || c.mj_campaign_data.order_data[0].sum_order_total;
      }))
    },
    maxReceiverSalesSum() {
      return Math.max.apply(Math, this.relevantCampaigns.map(function (c) {
        return !c.recipient_order_total || c.recipient_order_total;
      }))
    },
    directSalesSum() {
      return this.relevantCampaigns.reduce((total, next) => !next.mj_campaign_data.order_data[0] ? total : total + Number(next.mj_campaign_data.order_data[0].sum_order_total), 0)
    },
    maxDownloadCount() {
      let max, sum = 0
      if (this.downloadLogs.length === 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.totalDownloadCount = 0
        return max
      }
      for (let campaignStats in this.downloadLogs) {
        let downloads = Object.values(this.downloadLogs[campaignStats]).reduce((a, b) => a + b)
        max = max > downloads ? max : downloads
        sum = sum + downloads
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalDownloadCount = sum
      return max
    },
    relevantCampaigns() {
      return this.campaigns.filter(c => c.mj_campaign_data !== null && c.mj_campaign_data.count_delivered > 1000)
    },
    currentReceivers() {
      return this.relevantCampaigns.reduce((a, b) => {
        return new Date(a.mj_campaign_data.date) > new Date(b.mj_campaign_data.date) ? a : b;
      }).mj_campaign_data.count_delivered;
    },
    averageReceivers() {
      return this.relevantCampaigns.reduce((total, next) => total + next.mj_campaign_data.count_delivered, 0) / this.relevantCampaigns.length;
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const {data} = await NewslettersRepository.getNewsletterCampaigns()
      // this.campaigns = data
      const logData = await DownloadLogsRepository.getNewsletterDownloads()
      this.downloadLogs = logData.data
      this.campaigns = data.map(c => (
          {
            ...c,
            downloads: this.downloadLogs[c.code] ? Object.values(this.downloadLogs[c.code])?.reduce((a, b) => a + b) : 0,
            clickRevenue: c.mj_campaign_data?.order_data[0]?.sum_order_total || 0,
          }
      ))
      this.loading = false
    },
    async updateNewsletterStats() {
      this.loading = true
      await NewslettersRepository.updateCampaignes()
      await this.updateCampaignOrders()
      await this.fetch()
    },
    async updateCampaignOrders() {
      this.relevantCampaigns.map(async function (c) { // eventually filter by date
            await NewslettersRepository.updateCampaignesOrderData(c.mj_campaign_data.id)
          }
      )
    },
    openDetails(item) {
      this.campaign = item.mj_campaign_data
      this.dialog = true
    },
    closeDetails() {
      this.dialog = false
      this.campaign = null
    },
    getCampaignById(id) {
      return this.campaigns.find(c => c.id === id)
    },
    log(data) {
      console.log(data)
    },
    sum(items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    }
  },
  created() {
    this.fetch();
    this.$vuetify.theme.dark = false
  },
}
</script>

<style scoped>

</style>
