<template>
  <v-app id="inspire">

    <Menues/>

    <v-main>
      <div class="text-left">
        <v-sheet color="grey lighten-2" class="py-5 px-5">
          <h3>Affiliate Orders |
            <small>
              {{ from | moment('DD.MM.YYYY') }} - {{ to | moment('DD.MM.YYYY') }}
            </small>
          </h3>
        </v-sheet>
      </div>

      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="slide-y-transition"
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :loading="loading"
              absolute
              dark
              fab
              top
              right
              color="primary"
              v-bind="attrs"
              v-on="on"
              class="mt-15"
          >
            <v-icon>{{ menu ? 'mdi-calendar' : 'mdi-calendar-search' }}</v-icon>
          </v-btn>
        </template>

        <v-date-picker
            v-model="dates"
            range
            :max="new Date().toISOString().substr(0, 10)"
            @change="changeRange"
        />
      </v-menu>

      <v-data-table
          v-if="statistics.affiliates"
          :items="affiliates"
          item-key="affiliate"
          :expanded.sync="expanded"
          :headers="headers"
          show-expand
          sort-by="sum"
          sort-desc
      >
        <template v-slot:item.campaigns="{ item }">
          {{ item.campaigns.length }}
        </template>

        <template v-slot:item.sum="{ item }">
          {{ floatFormat(item.sum,2,'.') }} €
        </template>

        <template v-slot:expanded-item="{ headers, item }" class="elevation-0">
          <td :colspan="headers.length" class="elevation-0">
            <v-row class="my-3">
              <v-col
                  v-for="entry in item.campaigns"
                  :key="entry.campaign"
                  cols="12"
                  md="4"
              >
                <v-card>
                  <v-card-title>
                    <v-chip>
                      {{ entry.campaign !== 'null' ? entry.campaign : 'N/A' }}
                    </v-chip>
                  </v-card-title>
                  <v-simple-table>
                    <tbody>
                    <tr>
                      <th>Bestellungen</th>
                      <td>{{ entry.count }}</td>
                    </tr>
                    <tr>
                      <th>Brutto-Umsatz</th>
                      <td>{{ floatFormat(entry.sum,2,'.') }} €</td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-main>
  </v-app>
</template>

<script>
import Menues from '@/components/layout/Menues';
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
import FormatterMixin from "@/mixins/FormatterMixin";
import moment from 'moment-timezone'

const MarketingRepository = RepositoryFactory.get('marketing');

export default {
  name: "AffiliateOrders",
  components: { Menues },
  mixins: [ FormatterMixin ],
  data() {
    return {
      loading: false,
      statistics: {},
      headers: [
        {text: 'Affiliate', value: 'affiliate'},
        {text: 'Kampagnen', value: 'campaigns'},
        {text: 'Bestellungen', value: 'count', align: 'right'},
        {text: 'Umsatz', value: 'sum', align: 'right'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      dates: [],
      menu: false,
    }
  },
  computed: {
    affiliates() {
      let affiliatesArray = []
      for (const [key, value] of Object.entries(this.statistics.affiliates)) {
        let campaingsArray = []
        for (const [keyC, valueC] of Object.entries(value.campaigns)) {
          campaingsArray.push({
            campaign: keyC,
            count: valueC.count,
            sum: valueC.sum,
          })
        }
        affiliatesArray.push({
          affiliate: key,
          count: value.count,
          sum: value.sum,
          campaigns: campaingsArray,
        })
      }
      return affiliatesArray
    }
  },
  created() {
    this.dates = [this.from, this.to];
    this.fetch();
    this.$vuetify.theme.dark = false
  },
  methods: {
    async fetch() {
      this.loading = true
      const {data} = await MarketingRepository.getAffiliateOrders({from: this.from, to: this.to})
      this.statistics = data
      this.loading = false
    },
    changeRange: function () {
      if (this.dates[0] <= this.dates[1]) {
        this.from = this.dates[0];
        this.to = this.dates[1];
      } else {
        this.from = this.dates[1];
        this.to = this.dates[0];
      }
      this.fetch();
      this.menu = false;
    },
  },
}
</script>

<style scoped>
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
  background: #f1f1f1;
}
</style>