import Repository from "./Repository";

const resource = "/intranet/download_log";
const resource_stats_free = "/intranet/download_log_free_products";
const resource_stats_total = "/intranet/download_log_stats";
const resource_stats_newsletter = "/intranet/download_log_newsletter";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    getStatsFree(from, to){
        const params = {
            from: from,
            to: to,
        };
        return Repository.get(`${resource_stats_free}/`, {params});
    },
    getStats(){
        return Repository.get(`${resource_stats_total}/`);
    },
    getNewsletterDownloads() {
        return Repository.get(`${resource_stats_newsletter}/`);
    }
}
