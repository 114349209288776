<template>
    <v-app id="inspire">
        <router-view/>
        <MessageService/>
    </v-app>
</template>

<script>
    import repository from "./repositories/djangoBackend/Repository";
    import MessageService from "./components/services/MessageService";
    import store from './store/index';
    import router from './router/index'
    import {Promise} from "es6-promise";

    export default {
        name: 'App',
        components: {
            MessageService,
        },
        created: function () {
            repository.interceptors.response.use(
                function (response) {
                    return response;
                },
                function (error) {
                    if (error.response.status !== 401 || (error.response.status === 401 && error.response.config.__isRetryRequest)) {
                        return new Promise((resolve, reject) => {
                            store.dispatch('logout');
                            router.push({path: '/login'});
                            reject(error);
                        });
                    }

                    if (error.response.status === 401 && error.response.config && !error.response.config.__isRetryRequest) {
                        error.response.config.__isRetryRequest = true;
                        return store.dispatch('refresh')
                            .then(function () {
                                return new Promise((resolve, reject) => {
                                    repository.request(error.response.config)
                                        .then(response => {
                                            resolve(response);
                                        }).catch((error) => {
                                        reject(error);
                                    })
                                });
                            }).catch(error => {
                                store.dispatch('logout');
                                router.push({path: '/login', message: error});
                            });
                    } else {
                        store.dispatch('logout');
                        router.push({path: '/login'});
                    }
                });
        }
    }
</script>

<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
</style>
