<template>
  <v-app id="inspire">

    <Menues/>

    <v-main>
      <v-card flat tile class="elevation-0">
        <v-toolbar dense color="grey lighten-2" class="elevation-0">
          <v-toolbar-title class="black--text">
            <v-icon left>mdi-book-settings</v-icon>
            {{ productData.name }} <small class="font-weight-light"> | {{ productData.productNumber}}</small>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon :href="`https://clv.de/${productData.seoPath}`" target="_blank">
            <v-icon color="primary">
              mdi-storefront-outline
            </v-icon>
          </v-btn>
          <v-btn icon :href="`https://clv.de/admin#/sw/product/detail/${productData.sw_id}/base`" target="_blank">
            <v-icon color="primary">
              mdi-application-cog
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-img v-if="productData.coverPath" :src="productData.coverPath" class="d-flex flex-column"/>
            </v-col>
            <v-col cols="12" md="5" class="d-flex flex-column">
              <v-card class="flex d-flex flex-column">
                <v-card-title class="headline">
                    Beschreibung
                </v-card-title>
                <v-card-text>
                {{ productData.description }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" class="d-flex flex-column">
              <v-card class="flex d-flex flex-column">
                <v-card-title class="headline">
                  Dateien
                </v-card-title>
                <v-card-text>
                  <v-simple-table
                      v-if="freeDownloadsFields.filter(f => productData[f.name] !== null ).length > 0"
                  >
                    <template v-slot:default>
                      <tbody>
                      <tr
                          v-for="item in freeDownloadsFields.filter(f => productData[f.name] !== null )"
                          :key="item.name"
                      >
                        <td>{{ item.description }}</td>
                        <td>{{ productData[item.name] }}</td>
                        <td>
                          <v-btn
                              icon
                              :disabled="loadingDocument !== ''"
                              @click="forceDocumentUpdate(productData[item.name])"
                              :loading="loadingDocument === productData[item.name]"
                          >
                            <v-icon>
                              mdi-reload
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table v-if="documentFields.filter(f => productData[f.name] !== null ).length > 0">
                    <template v-slot:default>
                      <tbody>
                      <tr
                          v-for="item in documentFields.filter(f => productData[f.name] !== null )"
                          :key="item.name"
                      >
                        <td>{{ item.description }}</td>
                        <td>{{ productData[item.name] }}</td>
                        <td>
                          <v-btn
                              icon
                              :disabled="loadingDocument !== ''"
                              @click="forceDocumentUpdate(productData[item.name])"
                              :loading="loadingDocument === productData[item.name]"
                          >
                            <v-icon>
                              mdi-reload
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import Menues from "@/components/layout/Menues";
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
import {mapState} from "vuex";

const ArticleCacheRepository = RepositoryFactory.get('articleCache');

export default {
  name: "ProductDetails",
  components: { Menues },
  data() {
    return {
      currentCatalogId: this.$route.params.id,
      loadingDocument: '',
    }
  },
  computed: {
    ...mapState(['articleCache']),
    productData: function () {
      return this.articleCache.find(p => p.sw_id === this.currentCatalogId)
    },
    documentFields: function () {
      let fields = []
      for (let i = 1; i < 21; i++) {
        fields.push(
          {
            description: 'Dokument ' + i.toString(),
            name: 'custom_article_documents_' + i.toString().padStart(2, '0')
          }
        )
      }
      return fields
    },
    freeDownloadsFields: function () {
      let fields = []
      for (let i = 1; i < 2; i++) {
        fields.push(
          {
            description: 'Kostenloser Download ' + i.toString(),
            name: 'download_asset_free_' + i.toString()
          }
        )
      }
      return fields
    },
  },
  methods: {
    async forceDocumentUpdate(id) {
      this.loadingDocument = id
      await ArticleCacheRepository.forceFullAssetUpdate(id);
      this.loadingDocument = ''
    }
  },
}
</script>

<style scoped>

</style>