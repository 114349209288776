import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts';
import loadFunnel from 'highcharts/modules/funnel';
import loadMore from 'highcharts/highcharts-more';
import loadDumpbell from 'highcharts/modules/dumbbell';
import loadLollipop from 'highcharts/modules/lollipop';

Vue.use(Vuetify);

loadFunnel(Highcharts);
loadMore(Highcharts);
loadDumpbell(Highcharts);
loadLollipop(Highcharts);
Vue.use(HighchartsVue, { Highcharts });

import de from 'vuetify/lib/locale/de'

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: '#88b6cb',
                secondary: '#c01500',
                accent: '#c01500',
                error: '#b71c1c',
            },
        },
    },
});
