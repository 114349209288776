<template>
  <v-dialog v-model="dialog" max-width="450px" @click:outside="close" @keydown.esc="close">
    <v-card :loading="loading">
      <v-form ref="campaignForm" v-model="valid" lazy-validation>
        <template slot="progress">
          <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
        </template>
        <v-card-title>
          <span class="headline">Kampagne {{ campaign.id ? 'bearbeiten' : 'erstellen' }}</span>
        </v-card-title>
        <v-card-subtitle v-if="campaign.id">Kampagne {{ campaign.id ? 'bearbeiten' : 'erstellen' }}</v-card-subtitle>
        <v-card-text class="pt-5">

          <v-text-field
              v-model="item.name"
              filled
              label="Name"
              :rules="[(v) => !!v || 'Bitte Namen eingeben.']"
              :disabled="item.queue_generated_at !== null && !!campaign.id"
          />

          <v-text-field
              v-model="item.code"
              filled
              label="Campaigne Code"
              :rules="[(v) => !!v || 'Bitte Code angeben.']"
              :disabled="item.queue_generated_at !== null && !!campaign.id"
          />

          <v-autocomplete
              :loading="templates.length === 0"
              v-model="item.template_id"
              :items="templates"
              item-text="Name"
              item-value="ID"
              :rules="[(v) => !!v || 'Bitte Template auswählen.']"
              required
              filled
              label="Template"
              :disabled="item.queue_generated_at !== null && !!campaign.id"
          />

          <v-combobox
              v-model="selectedLists"
              :items="lists"
              label="Kontaktlisten"
              item-value="id"
              item-text="name"
              multiple
              filled
              chips
              :rules="[(v) => v.length >= 1 || 'Bitte mindestens eine Liste auswählen.']"
              :disabled="item.queue_generated_at !== null && !!campaign.id"
          ></v-combobox>

          <v-text-field
              v-model="item.url_params"
              filled
              label="Parameter"
              :disabled="item.queue_generated_at !== null && !!campaign.id"
          />

          <v-switch
              v-model="item.public"
              label="Kampagne im Archiv veröffentlichen"
          />

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Schließen</v-btn>
          <v-btn color="primary" elevation="0" @click="save">Speichern</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";

const NewslettersRepository = RepositoryFactory.get('newsletters');

export default {
  name: "DialogIntranetCampaignEdit",
  props: {
    dialog: Boolean,
    campaign: {
      Type: Object,
      Default: {},
    }
  },
  data() {
    return {
      loading: false,
      valid: true,
      templates: [],
      lists: [],
      template: null,
      selectedLists: [],
      item: {},
    }
  },
  watch: {
    async dialog(open) {
      if (open) {
        this.valid = true
        await this.fetch()
        if(this.campaign.id)
          this.init()
      }

    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const {data} = await NewslettersRepository.getMailjetTemplates()
      this.templates = data.Data
      const result = await NewslettersRepository.getLists()
      this.lists = result.data
      this.loading = false
    },
    async save() {
      this.loading = true;
      if (this.$refs.campaignForm.validate()) {
        this.item = {...this.item, lists: this.selectedLists.map(a => a.id)}
        let result = {}
        if (!this.campaign.id)
          result = await NewslettersRepository.createNewsletterCampaigns(this.item)
        else
          result = await NewslettersRepository.updateNewsletterCampaigns(this.item.id, this.item)
        this.$emit('saved', result.data);
        await this.close()
      }
      this.loading = false;
    },
    init() {
      this.item = {...this.campaign}
      this.selectedLists = this.lists.filter(l => this.campaign.lists.includes(l.id))
    },
    async close() {
      await this.$emit('close');
      this.item = {}
    },
  },
}
</script>

<style scoped>
</style>
