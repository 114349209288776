<template>
    <v-app id="inspire">
        <v-main class="full-cover-bg">
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="mx-auto elevation-12" max-width="350px">
                            <v-form class="login" @submit.prevent="login">
                                <v-toolbar color="primary" dark flat>
                                    <v-toolbar-title class="mx-auto text-lg-h5">CLV Intranet</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="px-5 py-5">

                                    <p>
                                        Bitte melden Sie sich mit Ihren Zugangsdaten am System an. Sollten Sie keine
                                        Zugangsdaten erhalten haben, schreiben Sie uns gerne eine Nachricht an <a
                                            href="mailto:info@clv.de">info@clv.de</a>.
                                    </p>

                                    <v-text-field
                                            label="Benutzername" required v-model="username" id="username" name="username" prepend-icon="mdi-account" type="text">
                                    </v-text-field>

                                    <v-text-field
                                            id="password" required v-model="password" label="Passwort" name="password" prepend-icon="mdi-lock"
                                            type="password">
                                    </v-text-field>

                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block depressed color="primary" type="submit">Anmelden</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import { mapState } from 'vuex';
    import { RepositoryFactory } from "@/repositories/djangoBackend/RepositoryFactory";

    const ArticleCacheRepository = RepositoryFactory.get('articleCache');
    const NewslettersRepository = RepositoryFactory.get('newsletters');

    export default {
        name: "Login",
        props: {
            source: String,
        },
        data() {
            return {
                username: "",
                password: "",
                errorMessage: 'Die Anmeldung ist leider fehlgeschlagen.',
            }
        },
        methods: {
            login: function () {
                let username = this.username;
                let password = this.password;
                this.$store.dispatch('login', {username, password})
                    .then()
                    .catch(err => {console.log(err);
                this.$store.dispatch('setMessage', this.errorMessage)})
            },
            async updateArticleCache() {
                await ArticleCacheRepository.update();
            },
            async updateNewsletterStats() {
              await NewslettersRepository.updateCampaignes();
            },
        },
        computed: mapState(['status']),
        watch: {
            status(newValue) {
                if (newValue === 'success') {
                    this.$store.dispatch('getConfig');
                    this.$store.dispatch('getArticleCache');
                    this.$store.dispatch('setMessage', 'Herzlich Willkommen');
                    this.$router.push({ path: '/' });
                    this.updateArticleCache();
                    this.updateNewsletterStats();
                }
            },
        },
    }
</script>

<style>
    .full-cover-bg {
        background-image: url("../assets/clv_login_bg_02.jpg");
        background-size: cover;
    }
</style>
