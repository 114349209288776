<template>
  <v-card elevation="0">
    <v-toolbar class="elevation-0 rounded-0 grey lighten-3">
      <v-toolbar-title class="black--text">Kampagnenverwaltung</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              elevation="0"
              @click="createDialog = true"
          >
            <v-icon left>mdi-plus</v-icon>
            erstellen
          </v-btn>
        </template>
        <span>Kampagne erstellen</span>
      </v-tooltip>

      <DialogIntranetCampaignEdit
          :dialog="createDialog"
          :campaign="campaign"
          @close="close"
          @saved="saved"
      />

      <DialogViewNewsletter
          :dialog="viewDialog"
          :campaign="campaign"
          @close="viewDialog = false"
      />
    </v-toolbar>

    <v-data-table
        :headers="headers"
        :items="campaigns"
        :items-per-page="25"
        :sort-by="['created_at', 'code']"
        sort-desc
        class="my-5"
        item-key="id"
    >
      <template v-slot:item.created_at="{item}">
        {{ item.created_at | moment('DD.MM.YYYY') }}
      </template>

      <template v-slot:item.public="{item}">
        <v-icon>{{ item.public ? 'mdi-check' : 'mdi-close' }}</v-icon>
      </template>

      <template v-slot:item.lists="{item}">
        <span
            v-for="list in item.lists"
            :key="list"
        >
          <v-chip
              v-if="lists.find(l => l.id === list)"
              class="mb-1"
          >
          {{ lists.find(l => l.id === list).name }}
        </v-chip>
          <br>
        </span>
      </template>

      <template v-slot:item.queue_generated_at="{item}">
        <div class="small">
          <v-icon small>mdi-email-sync-outline</v-icon>
          <span v-if="item.queue_generated_at"> {{ item.queue_generated_at | moment('DD.MM.YYYY HH:mm')}}</span>
          <span v-else> ---</span>
        </div>
        <div class="small">
          <v-icon small>mdi-email-check-outline</v-icon>
          <span v-if="item.send_completed_at"> {{ item.send_completed_at | moment('DD.MM.YYYY HH:mm') }}</span>
          <span v-else> ---</span>
        </div>
      </template>

      <template v-slot:item.actions="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="editCampaign(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-email-edit-outline</v-icon>
            </v-btn>
          </template>
          Kampagne bearbeiten
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="viewNewsletter(item)"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-email-search-outline</v-icon>
            </v-btn>
          </template>
          Newsletter ansehen
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="!item.template_id || item.queue_generated_at !== null"
                :loading="sendingTest === item.id"
                @click="sendTest(item)"
                class="mr-2"
            >
              <v-icon>mdi-send-lock</v-icon>
            </v-btn>
          </template>
          Testversand
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="!(item.name && item.code && item.template_id) && generating === item.id || item.queue_generated_at !== null || item.lists.length === 0"
                :loading="generating === item.id"
                @click="generateQueue(item)">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
          Versand der Kampagne vorbereiten
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
import DialogIntranetCampaignEdit from "@/components/newsletter/DialogIntranetCampaignEdit";
import DialogViewNewsletter from "@/components/newsletter/DialogViewNewsletter";

const NewslettersRepository = RepositoryFactory.get('newsletters');

export default {
  name: "NewsletterCampaignManagement",
  components: {
    DialogIntranetCampaignEdit,
    DialogViewNewsletter,
  },
  created() {
    this.fetch();
    this.$vuetify.theme.dark = false
  },
  data() {
    return {
      loading: false,
      generating: null,
      sendingTest: null,
      createDialog: false,
      viewDialog: false,
      campaigns: [],
      campaign: {},
      lists: [],
      headers: [
        {text: 'Datum', value: 'created_at'},
        {text: 'Code', value: 'code'},
        {text: 'Name', value: 'name'},
        {text: 'Template', value: 'template_id'},
        // {text: 'Parameter', value: 'url_params'},
        {text: 'Listen', value: 'lists'},
        {text: 'Versand', value: 'queue_generated_at'},
        {text: 'Archiv', value: 'public'},
        {text: 'Aktionen', value: 'actions', align: 'end'},
      ],
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const {data} = await NewslettersRepository.getNewsletterCampaigns()
      this.campaigns = data
      const result = await NewslettersRepository.getLists()
      this.lists = result.data
      this.loading = false
    },
    async saved(data) {
      await this.fetch()
      await this.$store.dispatch('setMessage', 'Die Kampagne "' + data.name + '" wurde erfolgreich gespeichert.');
    },
    async close() {
      this.campaign = {}
      this.createDialog = false
      this.viewDialog = false
    },
    editCampaign(item) {
      this.campaign = item
      this.createDialog = true
    },
    viewNewsletter(item) {
      this.viewDialog = true
      this.campaign = item
    },
    async generateQueue(campaign) {
      this.generating = campaign.id
      const result = await NewslettersRepository.generateNewsletterQueue(campaign.id)
      if (result.status === 200 && result.data)
        await this.$store.dispatch('setMessage', result.data);
      else
        await this.$store.dispatch('setMessage', 'Ein Fehler ist aufgetreten');
      await this.fetch()
      this.generating = null
    },
    async sendTest(campaign) {
      this.sending = campaign.id
      const result = await NewslettersRepository.sendTestNewsletter(campaign.id)
      if (result.status === 200 && result.data)
        await this.$store.dispatch('setMessage', 'Der Test wurde erfolgreich verschickt.');
      else
        await this.$store.dispatch('setMessage', 'Ein Fehler ist aufgetreten');
      this.sending = null
    }
  }
}
</script>

<style scoped>

</style>