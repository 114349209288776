import axios from "axios";
import store from '../../store';

const baseDomain = "https://api.clv.de";
// const baseDomain = "http://localhost:8199";
const baseURL = `${baseDomain}/`;
// const Bearer = 'Bearer ';

export default axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: {
            toString () {
                return `Bearer ${store.state.access}`
            }
        }
    },
});
