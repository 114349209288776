var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{staticClass:"elevation-0 rounded-0 grey lighten-3"},[_c('v-toolbar-title',{staticClass:"black--text"},[_vm._v("Kampagnenverwaltung")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"elevation":"0"},on:{"click":function($event){_vm.createDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" erstellen ")],1)]}}])},[_c('span',[_vm._v("Kampagne erstellen")])]),_c('DialogIntranetCampaignEdit',{attrs:{"dialog":_vm.createDialog,"campaign":_vm.campaign},on:{"close":_vm.close,"saved":_vm.saved}}),_c('DialogViewNewsletter',{attrs:{"dialog":_vm.viewDialog,"campaign":_vm.campaign},on:{"close":function($event){_vm.viewDialog = false}}})],1),_c('v-data-table',{staticClass:"my-5",attrs:{"headers":_vm.headers,"items":_vm.campaigns,"items-per-page":25,"sort-by":['created_at', 'code'],"sort-desc":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'DD.MM.YYYY'))+" ")]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.public ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.lists",fn:function(ref){
var item = ref.item;
return _vm._l((item.lists),function(list){return _c('span',{key:list},[(_vm.lists.find(function (l) { return l.id === list; }))?_c('v-chip',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.lists.find(function (l) { return l.id === list; }).name)+" ")]):_vm._e(),_c('br')],1)})}},{key:"item.queue_generated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"small"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email-sync-outline")]),(item.queue_generated_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.queue_generated_at,'DD.MM.YYYY HH:mm')))]):_c('span',[_vm._v(" ---")])],1),_c('div',{staticClass:"small"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-email-check-outline")]),(item.send_completed_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.send_completed_at,'DD.MM.YYYY HH:mm')))]):_c('span',[_vm._v(" ---")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.editCampaign(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-edit-outline")])],1)]}}],null,true)},[_vm._v(" Kampagne bearbeiten ")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.viewNewsletter(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-search-outline")])],1)]}}],null,true)},[_vm._v(" Newsletter ansehen ")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","disabled":!item.template_id || item.queue_generated_at !== null,"loading":_vm.sendingTest === item.id},on:{"click":function($event){return _vm.sendTest(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send-lock")])],1)]}}],null,true)},[_vm._v(" Testversand ")]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!(item.name && item.code && item.template_id) && _vm.generating === item.id || item.queue_generated_at !== null || item.lists.length === 0,"loading":_vm.generating === item.id},on:{"click":function($event){return _vm.generateQueue(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,true)},[_vm._v(" Versand der Kampagne vorbereiten ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }