import Repository from "./Repository";

const resource = "/intranet/catalog";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    getByID(id){
        return Repository.get(`${resource}/`+id+'/');
    },
    addCatalog(title, description){
        let formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        return Repository.post(`${resource}/`, formData);
    },
    removeCatalog(catalogId){
        return Repository.delete(`${resource}/${catalogId}/`);
    },
    addArticle(catalogId, articleId){
        let formData = new FormData();
        formData.append('article_id', articleId);
        return Repository.post(`${resource}/`+catalogId+'/add_article/', formData);
    },
    removeArticle(catalogId, articleId){
        let formData = new FormData();
        formData.append('article_id', articleId);
        return Repository.post(`${resource}/`+catalogId+'/remove_article/', formData);
    },
    changeOrder(catalogId, articleId, newOrder){
        let formData = new FormData();
        formData.append('article_id', articleId);
        formData.append('order', newOrder);
        return Repository.post(`${resource}/`+catalogId+'/change_order/', formData);
    },
    getFullCatalogXML(){
        return Repository.get('/intranet/retail_catalog_download/', {responseType: 'blob'});
    },
}
