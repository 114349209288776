<template>
  <div>
    <div class="bar text-center" :style="progressStyles">
      {{ label }} <small v-if="postfix">{{ postfix}}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    label: String,
    postfix: String,
    color: {
      type: String,
      default: '#88b6cb'
    },
    backgroundColor: {
      type: String,
      default: '#88b6cb42'
    },
    percentage: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 25
    }
  },
  computed: {
    progressStyles() {
      return {
        height: `${this.height}px`,
        "line-height": `${this.height}px`,
        background: `${this.backgroundColor} linear-gradient(90deg, ${this.color} 0%, ${this.color} ${this.percentage}%, ${this.backgroundColor} ${this.percentage}%, ${this.backgroundColor} 100%)`,
      };
    }
  }
}
</script>

<style scoped>
.bar {
  width: 100%;
  /*height: 25px;*/
  border-radius: 4px;
  /*line-height: 25px;*/
}
</style>