import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Downloads from '../views/DownloadLogs.vue'
import Catalogs from '../views/Catalogs.vue'
import Catalog from '../views/Catalog.vue'
import PromotionOrders from '../views/promotions/PromotionOrders'
import Newsletters from '../views/Newsletters'
import ProductsList from '../views/products/ProductsList'
import ProductDetails from '@/views/products/ProductDetails'
import AffiliateOrders from "@/views/promotions/AffiliateOrders";
import RevenueStats from "@/views/RevenueStats";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Übersicht',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/downloads',
        name: 'Downloads',
        component: Downloads,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/catalogs',
        name: 'Kataloge',
        component: Catalogs,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/catalog/:id/',
        name: 'Katalog',
        component: Catalog,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/promotions/orders',
        name: 'Bestellungen',
        component: PromotionOrders,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/affiliates/orders',
        name: 'Affiliate Bestellungen',
        component: AffiliateOrders,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/revenues',
        name: 'Umsatz-Statistiken',
        component: RevenueStats,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/newsletters',
        name: 'Newsletters',
        component: Newsletters,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/products/list',
        name: 'Produkte',
        component: ProductsList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/products/details/:id/',
        name: 'Produkt Details',
        component: ProductDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/clever',
        meta: {
            externalLink: true,
            href: 'https://api.clv.de/admin',
            newWindow: true,
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return
        }
        next('/login')
    } else {
        next();
    }

    let target = "_parent";
    if(to.matched.some(record => record.meta.externalLink)) {
        if(to.matched.some(record => record.meta.newWindow)) {
            target = "_blank";
        }
        next(from.path);
        window.open(to.meta.href, target);
    } else {
        next();
    }

    if(to.path === '/login' && store.getters.isLoggedIn) {
        next('/')
    }
});

export default router
