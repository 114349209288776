<template>
  <v-app id="inspire">

    <Menues/>

    <v-main>
      <v-card elevation="0">
        <v-toolbar
            color="grey lighten-2"
            flat
            height="80px"
            rounded="0"
        >

          <v-toolbar-title class="black--text">Aktionsbestellungen</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-autocomplete
              :items="orders"
              v-model="selectedPromotion"
              label="Aktion"
              class="grey lighten-2 w-500px"
              item-text="promotion_article"
              item-value="promotion_article.productNumber"
              filled
              clearable
              dense
              rounded
              hide-details
          >
            <template v-slot:selection="data">
              {{ getProductData(data.item.promotion_article, 'productNumber', 'name') }}
            </template>
            <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getProductData(data.item.promotion_article, 'productNumber', 'name') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ data.item.promotion_article }}
                  </v-list-item-subtitle>
                </v-list-item-content>
            </template>
          </v-autocomplete>

        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="orders"
            :items-per-page="10"
            :search="search"
            :loading="loading"
            loading-text="Daten werden geladen... Bitte warten"
            :sort-by="['date']" :sort-desc="[true]"
        >
          <template v-slot:item.date="{ item }">
            {{ item.date | moment("DD.MM.YYYY HH:mm") }}
          </template>

          <template v-slot:item.name="{ item }">
            {{ item.name }} {{ item.surname }}
          </template>

          <template v-slot:item.street="{ item }">
            {{ item.street }} {{ item.house_number }}
          </template>

          <template v-slot:item.city="{ item }">
            {{ item.zip_code }} {{ item.city }}
          </template>

          <template v-slot:item.promotion_article="{ item }">
            {{ item.promotion_article }} <br><small>{{ getProductData(item.promotion_article, 'productNumber', 'name') }}</small>
          </template>

          <template v-slot:item.exported="{ item }">
            <v-icon>{{ stateIcons[item.exported] }}</v-icon>
          </template>

        </v-data-table>

      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import Menues from '@/components/layout/Menues';
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
import articleDataMixin from "@/mixins/articleDataMixin";

const PromotionOrdersRepository = RepositoryFactory.get('promotionOrders');

export default {
  name: "PromotionOrders",
  components: {
    Menues,
  },
  props: {
    source: String,
  },
  mixins: [ articleDataMixin ],
  data() {
    return {
      search: '',
      orders: [],
      product: [],
      loading: true,
      selectedPromotion: null,
      headers: [
        {text: 'Bestellnummer', value: 'id'},
        {text: 'Datum', value: 'date'},
        {text: 'Name', value: 'name'},
        {text: 'E-Mail', value: 'email'},
        {text: 'Straße', value: 'street'},
        {text: 'Ort', value: 'city'},
        {
          text: 'Produkt',
          value: 'promotion_article',
          filter: (value) => {
            if (!this.selectedPromotion) return true;
            return this.selectedPromotion === value;
          },
        },
        {text: 'Exportiert', value: 'exported', align: 'end'},
      ],
      stateIcons: {
        true: 'mdi-check-circle',
        false: 'mdi-circle-outline',
      },
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      const {data} = await PromotionOrdersRepository.get();
      this.orders = data;
      this.loading = false;
    },
  },
  created() {
    this.fetch();
    // this.fetchProduct();
    this.$vuetify.theme.dark = false
  },
}

</script>

<style>
.w-500px {
  max-width: 500px;
}
</style>