<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="close" @keydown.esc="close">
    <v-card :loading="loading">
      <v-toolbar color="primary">
        <v-toolbar-title>
          <v-icon left color="white">mdi-email-search-outline</v-icon>
          Newsletter Vorschau -
          {{ campaignDetails.name }}
        </v-toolbar-title>
        <v-spacer/>
        <v-btn @click="close" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <div v-html="campaignDetails.html_content"></div>
    </v-card>
  </v-dialog>
</template>

<script>
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";

const NewslettersRepository = RepositoryFactory.get('newsletters');

export default {
  name: "DialogViewNewsletter",
  props: {
    dialog: Boolean,
    campaign: {
      Type: Object,
      Default: {},
    }
  },
  data() {
    return {
      loading: false,
      campaignDetails: {}
    }
  },
  watch: {
    async dialog(open) {
      if (open) {
        await this.fetch()
      }
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const {data} = await NewslettersRepository.getNewsletterCampaign(this.campaign.id)
      this.campaignDetails = data
      this.loading = false
    },
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
</style>
