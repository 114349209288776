import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import Axios from 'axios'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import SlideUpDown from 'vue-slide-up-down'
import Highcharts from 'highcharts';

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
const access = localStorage.getItem('clvIntranetAccess');
if (access) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = access
}

Vue.use(VueMoment, {
    moment,
});

Vue.component('slide-up-down', SlideUpDown);

Highcharts.setOptions({
    lang: {
        thousandsSep: '.',
        decimalPoint: ','
    }
})

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
