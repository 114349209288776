import Repository from "./Repository";

const resource = "/intranet/newsletter_campaigns"
const templateResource = "/intranet/mailjet_templates";
const mailjetResource = "/intranet/mailjet_newsletter_campaigns";

export default {
    getNewsletterCampaigns() {
        return Repository.get(`${resource}/`);
    },
    getNewsletterCampaign(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    createNewsletterCampaigns(payload) {
        return Repository.post(`${resource}/`, payload);
    },
    updateNewsletterCampaigns(id, payload) {
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    generateNewsletterQueue(id) {
        return Repository.get(`${resource}/${id}/create_queue/`);
    },
    getLists() {
        return Repository.get(`/newsletter_lists/`);
    },
    getMailjetTemplates() {
        return Repository.get(`${templateResource}/`);
    },
    getCampaigns(){
        return Repository.get(`${mailjetResource}/`);
    },
    getCampaign(id){
        return Repository.get(`${mailjetResource}/${id}/`);
    },
    updateCampaignes(){
        return Repository.get(`${mailjetResource}/update_campaigns/`);
    },
    updateCampaignesOrderData(id){
        return Repository.get(`${mailjetResource}/${id}/update_order_data/`);
    },
    forceUpdateCampaign(id){
        return Repository.get(`${mailjetResource}/${id}/update_clicked_links/`);
    },
    sendTestNewsletter(id){
        return Repository.get(`${resource}/${id}/send_campaign_test/`);
    }
}