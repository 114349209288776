<template>
  <v-app id="inspire">

    <Menues/>

    <v-main>
      <v-card flat tile class="elevation-0">
        <v-toolbar dense color="grey lighten-2" class="elevation-0">
          <v-toolbar-title class="black--text">
            <v-icon left>mdi-book-settings</v-icon>
            Produktliste
          </v-toolbar-title>

          <v-spacer></v-spacer>

<!--          <v-tooltip left>-->
<!--            <template v-slot:activator="{ on, attrs}">-->
<!--              <v-btn-->
<!--                  to="/project/articles-preview"-->
<!--                  v-bind="attrs"-->
<!--                  v-on="on"-->
<!--                  absolute dark fab bottom right fixed color="primary" style="right: 90px;">-->
<!--                <v-icon>mdi-printer</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>Beiträge drucken</span>-->
<!--          </v-tooltip>-->

<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs}">-->
<!--              <v-btn @click="newItem"-->
<!--                     v-bind="attrs"-->
<!--                     v-on="on"-->
<!--                     absolute dark fab bottom right color="primary">-->
<!--                <v-icon>mdi-plus</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>Beitrag erstellen</span>-->
<!--          </v-tooltip>-->
        </v-toolbar>

        <v-card-title>
          <v-spacer/>
          <v-text-field
              style="max-width: 250px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Suchen"
              single-line
              hide-details
          />
        </v-card-title>

<!--        {{ articleCache[0] }}-->

        <v-data-table
            v-if="articleCache.length > 0"
            :headers="headers"
            :items="articleCache"
            :sort-by="['name']"
            :sort-desc="[false]"
            :items-per-page="15"
            :search="search"
            :loading="loading"
            loading-text="Daten werden geladen... Bitte warten"
        >

          <template v-slot:item.name="{ item }">
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="item.coverPath"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.productNumber"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:item.price_gross="{ item }">
            {{ floatFormat(item.price_gross,2,'.') }}€
          </template>

          <template v-slot:item.productNumber="{ item }">
            <span>
              <v-btn icon :href="`https://clv.de/${item.seoPath}`" target="_blank">
                <v-icon>
                  mdi-storefront-outline
                </v-icon>
              </v-btn>
              <v-btn icon :href="`https://clv.de/admin#/sw/product/detail/${item.sw_id}/base`" target="_blank">
                <v-icon>
                  mdi-application-cog
                </v-icon>
              </v-btn>
              <v-btn icon :to="`/products/details/${item.sw_id}`">
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </span>
          </template>

        </v-data-table>

      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Menues from '@/components/layout/Menues'
import coverMixin from '@/mixins/coverMixin'
import FormatterMixin from "@/mixins/FormatterMixin";

export default {
  name: "ProductsList",
  components: {
    Menues,
  },
  props: {
    source: String,
  },
  mixins: [coverMixin, FormatterMixin],
  data() {
    return {
      isShowForm: false,
      search: '',
      articles: [],
      loading: true,
      headers: [
        {text: 'Bezeichnung', value: 'name', sortable: true},
        {text: 'Preis', value: 'price_gross', sortable: true},
        {text: 'Artikelnummer', value: 'productNumber', align: 'end', sortable: false},
      ]
    }
  },
  computed: {
    ...mapState(['articleCache']),
  },
  methods: {
  },
  created() {
    this.$vuetify.theme.dark = false
    this.loading = false
  },
}
</script>

<style>
.header-back-icon {
  float: left;
  margin-top: -3px;
  margin-right: 10px;
}
</style>
