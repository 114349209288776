<template>
  <v-app id="inspire">

    <Menues/>

    <v-main>
      <v-card elevation="0">
        <v-toolbar
            color="primary"
            dark
            flat
            rounded="0"

        >
          <v-icon left>mdi-email-multiple-outline</v-icon>

          <v-toolbar-title class="text--black">Newsletter</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
              color="white"
              class="black--text mr-2"
              href="https://app.mailjet.com"
              target="_blank"
              elevation="0"
          >
            <v-icon left>mdi-open-in-new</v-icon>
            Mailjet
          </v-btn>

          <v-btn
              color="white"
              class="black--text"
              href="https://nl.clv.de/subscribe"
              target="_blank"
              elevation="0"
          >
            <v-icon left>mdi-open-in-new</v-icon>
            Formular
          </v-btn>

          <template v-slot:extension>
            <v-tabs
                v-model="tab"
                align-with-title
            >
              <v-tabs-slider color="secondary"></v-tabs-slider>

              <v-tab
                  v-for="item in items"
                  :key="item"
              >
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item key="Statistik" >
              <NewslettersCampaignStatistics/>
          </v-tab-item>
          <v-tab-item key="Kampagne">
              <NewsletterCampaignManagement/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import Menues from "@/components/layout/Menues";
import NewsletterCampaignManagement from "@/components/newsletter/NewsletterCampaignManagement";
import NewslettersCampaignStatistics from "@/components/newsletter/NewsletterCampaignStatistics";

export default {
  name: "Newsletters",
  components: {
    Menues,
    NewslettersCampaignStatistics,
    NewsletterCampaignManagement,
  },
  data() {
    return {
      tab: null,
      items: [
        'Statistik', 'Kampagnen'
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  created() {
    this.$vuetify.theme.dark = false
  },
}
</script>
