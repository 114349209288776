<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="close" @keydown.esc="close">
    <v-card :loading="loading" v-if="campaign">
      <template slot="progress">
        <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
      </template>
      <v-card-title>
        <span class="headline">Campaign Statistik</span>
      </v-card-title>
      <v-card-subtitle>{{ campaign.subject }}</v-card-subtitle>
        <v-card-text>
          <v-container>

            <highcharts
                v-if="chartOptions"
                :options="chartOptions"
            />

            <v-data-table
                :headers="headers"
                :items="campaign.clicked_links"
                :items-per-page="25"
                :sort-desc="[true]"
                class="my-5"
            >
              <template v-slot:item.link="{ item }">
                <a
                    v-if="item.link.includes('?')"
                    :href="`https://${item.link.substring(0, item.link.indexOf('?'))}`"
                    target="_blank"
                >
                  {{ item.link.substring(0, item.link.indexOf('?')) }}
                </a>
                <a
                    v-else
                    :href="`https://${item.link}`"
                    target="_blank"
                >
                  {{ item.link }}
                </a>
              </template>

              <template v-slot:item.code="{ item }">
                <v-icon>{{ item.link.includes('affiliateCode') && item.link.includes('campaignCode') ? 'mdi-check' : 'mdi-close' }}</v-icon>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Schließen</v-btn>
        </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import {Chart} from 'highcharts-vue'

export default {
  name: "DialogLinkStatistik",
  components: {
    highcharts: Chart
  },
  props: {
    dialog: Boolean,
    campaign: Object,
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        this.chartOptions = {
          chart: {
            type: 'funnel'
          },
          title: false,
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                    format: '<b>{point.name}</b> ({point.y:,.0f})',
                    softConnector: true
              },
              center: ['40%', '50%'],
                  neckWidth: '30%',
                  neckHeight: '25%',
                  width: '80%'
            }
          },
          legend: {
            enabled: false
          },
          series: [{
            name: 'Anzahl',
            data: [
              ['Empfänger', this.campaign.count_delivered],
              ['Öffnungen', this.campaign.count_opened],
              ['Klicks', this.campaign.count_clicked],
            ],
            colors: [
              '#88b6cb',
              '#498392',
              '#c01500',
            ],
          }],
              responsive: {
            rules: [{
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                plotOptions: {
                  series: {
                    dataLabels: {
                      inside: true
                    },
                    center: ['50%', '50%'],
                    width: '100%'
                  }
                }
              }
            }]
          },
          credits: {
            enabled: false
          },
        }
      }

    }
  },
  data() {
    return {
      loading: false,
      valid: true,
      headers: [
        {text: 'Link', value: 'link'},
        {text: 'Klicks', value: 'count_clicked'},
        {text: 'Code', value: 'code'},
      ],
      chartOptions: false,
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
.highcharts-figure,
.highcharts-data-table table {
  min-width: 360px;
  max-width: 600px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

</style>
