var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('Menues'),_c('v-main',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"grey lighten-2","flat":"","height":"80px","rounded":"0"}},[_c('v-toolbar-title',{staticClass:"black--text"},[_vm._v("Aktionsbestellungen")]),_c('v-spacer'),_c('v-autocomplete',{staticClass:"grey lighten-2 w-500px",attrs:{"items":_vm.orders,"label":"Aktion","item-text":"promotion_article","item-value":"promotion_article.productNumber","filled":"","clearable":"","dense":"","rounded":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getProductData(data.item.promotion_article, 'productNumber', 'name'))+" ")]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getProductData(data.item.promotion_article, 'productNumber', 'name'))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.promotion_article)+" ")])],1)]}}]),model:{value:(_vm.selectedPromotion),callback:function ($$v) {_vm.selectedPromotion=$$v},expression:"selectedPromotion"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":10,"search":_vm.search,"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","sort-by":['date'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"DD.MM.YYYY HH:mm"))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.surname)+" ")]}},{key:"item.street",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.street)+" "+_vm._s(item.house_number)+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.zip_code)+" "+_vm._s(item.city)+" ")]}},{key:"item.promotion_article",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.promotion_article)+" "),_c('br'),_c('small',[_vm._v(_vm._s(_vm.getProductData(item.promotion_article, 'productNumber', 'name')))])]}},{key:"item.exported",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.stateIcons[item.exported]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }