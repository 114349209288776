export default  {
  methods: {
    currencyFormat(num) {
      return (
        parseFloat(num)
          .toFixed(2)
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
      )
    },
    floatFormat(num, digits = 2, separator = '') {
      return (
          parseFloat(num)
              .toFixed(digits)
              .replace('.', ',')
              .toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
      )
    },
  },
}
