<template>
    <v-app id="inspire">

        <Menues/>

        <v-main>
            <div class="text-left">
                <v-sheet color="grey lighten-2" class="py-5 px-5"><h3>Umsatz-Statistiken</h3></v-sheet>
            </div>
            <div class="d-flex flex-row justify-center pa-5">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="slide-y-transition"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value=dateRangeText
                        label="Datumsbereich"
                        filled
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        max-width="200px"
                    >
                        <template v-slot:append>
                            <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                        </template>
                    </v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    range :max="new Date().toISOString().substr(0, 10)"
                    @change="changeRange"></v-date-picker>
            </v-menu>
            <v-text-field label="min. Bestellwert" type="number" v-model="min_amount" hide-details clearable @change="fetch" class="ml-4" step="0.1" filled/>
            <v-text-field label="max. Bestellwert" type="number" v-model="max_amount" hide-details clearable @change="fetch" class="ml-4" step="0.1" filled/>
            <v-text-field label="Referenz" v-model="ref_id" hide-details clearable @change="fetch" class="ml-4" filled/>
            <v-select label="Kundengruppe" v-model="customer_group_no" :items="customer_groups" item-text="name" item-value="number" hide-details clearable @change="fetch" class="ml-4" filled></v-select>
            </div>

          <v-card-text>
            <v-row dense >
              <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                <v-card color="#385F73" dark class="flex d-flex flex-column">
                  <v-card-title class="headline">Bestellungen</v-card-title>

                  <v-card-subtitle>
                    Summierte und durchschnittliche Anzahl der Bestellungen im ausgewählten Zeitraum unter Anwendung der Filter.
                  </v-card-subtitle>

                  <div v-if="loading" class="text-center">
                    <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
                  </div>
                  <v-card-text v-else>
                    <div class="stat-total-highlight">
                      {{ floatFormat(result.total, 0, '.') }}
                      <small> Ø {{ result.total > 0 ? floatFormat(result.total / numberOfDays(from, to), 1) : '0' }} pro Tag</small>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                <v-card color="#385F73" dark class="flex d-flex flex-column">
                  <v-card-title class="headline">Umsatzübersicht</v-card-title>

                  <v-card-subtitle>
                    Summe des Umsatzes im ausgewählten Zeitraum und unter Berücksichtigung der Filter sowie durchschnittlicher Warenkorbwert.
                  </v-card-subtitle>

                  <div v-if="loading" class="text-center mb-5">
                    <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
                  </div>
                  <v-card-text v-else>
                    <div v-if="result.aggregations !== undefined" class="stat-total-highlight">
                      {{ floatFormat(result.aggregations.total_sum.sum, 2, '.') }}€
                      <small> Ø {{ result.total > 0 ? floatFormat(result.aggregations.total_sum.sum / result.total, 2, '.') : '0' }}€ pro Warenkorb</small>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="d-flex flex-column">
                <v-card color="#385F73" dark class="flex d-flex flex-column">
                  <v-card-title class="headline">Bestellte Produkte</v-card-title>

                  <v-card-subtitle>
                    Anzahl der bestellten Produkte im gewählten Zeitraum und durchschnittliche Anzahl pro Bestellung
                  </v-card-subtitle>

                  <div v-if="loading" class="text-center mb-5">
                    <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
                  </div>
                  <v-card-text v-else>
                    <div v-if="result.aggregations !== undefined" class="stat-total-highlight">
                      {{ floatFormat(result.aggregations.product_count.sum, 0, '.') }}
                      <small> Ø {{ result.total > 0 ? floatFormat(result.aggregations.product_count.sum / result.total, 1, '.') : '0' }} pro Bestellung</small>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

            <v-container class="px-5 my-7">
                <div v-if="loading" class="text-center ">
                    <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
                </div>
                <highcharts v-else :options="chartOptions" :key="String(result.total)"/>
            </v-container>
        </v-main>
        
    </v-app>
</template>

<script>
    import Menues from '@/components/layout/Menues'
    import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";
    import moment from 'moment-timezone'
    import {Chart} from 'highcharts-vue'
    import FormatterMixin from "@/mixins/FormatterMixin";

    const RevenueRepository = RepositoryFactory.get('revenue');

    export default {
        name: "Umsätze",
        components: {
            Menues,
            highcharts: Chart
        },
        mixins: [FormatterMixin],
        data() {
            return {
                loading: true,
                menu: false,
                from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD'),
                dates: [],
                min_amount: null,
                max_amount: null,
                ref_id: null,
                customer_group_no: null,
                result: {},
                customer_groups: [
                    {number: 1, name: 'Standard-Kundengruppe'},
                    {number: 2, name: 'Händler'},
                    {number: 3, name: 'Büchertische'}
                ],
                chartOptions: {
                    chart: {
                        type: 'column'
                    },
                    title: false,
                    tooltip: {
                        shared: true,
                        pointFormat: '<b>{series.name}:</b> {point.y:,.2f} ( {point.percentage:.0f}% )<br>',
                        footerFormat: '<b>Bruttoumsatz (Summe):  {point.total:,.2f} </b>'
                    },
                    series: [],
                    xAxis: {
                        type: 'datetime',
                        labels: {
                            format: '{value:%a.<br>%d.%m.}'
                        }
                    },
                    yAxis: [
                        {
                            labels:{
                                format: '{value:,.2f} €'
                            },
                            title: {text: "Bruttoumsatz"},
                        },
                        {
                            title: {text: "Bestellungen"},
                            allowDecimals: false,
                            opposite: true
                        }
                    ],
                    credits: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            stacking: 'normal',
                            states: {
                                inactive: {
                                    opacity: 1,
                                },
                            }
                        }
                    },
                }
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await RevenueRepository.get(this.from, this.to, this.min_amount, this.max_amount, this.ref_id, this.customer_group_no);
                this.result = data;
                this.buildGraph()
                this.loading = false;
            },
            buildGraph: function () {
                if(!this.result) return false

                let revenue_direct_by_date = this.result.data.reduce((groups, order) => {
                    const date = order.orderDateTime.split('T')[0];
                    if (order.affiliateCode === null){
                        if (!groups[date]) {
                            groups[date] = order.amountTotal;
                        }else{
                            groups[date] = groups[date] + order.amountTotal;
                        }
                    }
                    return groups;
                    }, {});
                
                let revenue_google_by_date = this.result.data.reduce((groups, order) => {
                    const date = order.orderDateTime.split('T')[0];
                    if (order.affiliateCode === 'google'){
                        if (!groups[date]) {
                            groups[date] = order.amountTotal;
                        }else{
                            groups[date] = groups[date] + order.amountTotal;
                        }
                    }
                    return groups;
                    }, {});

                let revenue_newsletter_by_date = this.result.data.reduce((groups, order) => {
                    const date = order.orderDateTime.split('T')[0];
                    if (order.affiliateCode === 'newsletter'){
                        if (!groups[date]) {
                            groups[date] = order.amountTotal;
                        }else{
                            groups[date] = groups[date] + order.amountTotal;
                        }
                    }
                    return groups;
                    }, {});

                let revenue_other_by_date = this.result.data.reduce((groups, order) => {
                    const date = order.orderDateTime.split('T')[0];
                    if (order.affiliateCode !== null && order.affiliateCode !== 'google' && order.affiliateCode !== 'newsletter'){
                        if (!groups[date]) {
                            groups[date] = order.amountTotal;
                        }else{
                            groups[date] = groups[date] + order.amountTotal;
                        }
                    }
                    return groups;
                    }, {});

                let orders_by_date = this.result.data.reduce((groups, order) => {
                    const date = order.orderDateTime.split('T')[0];
                    if (!groups[date]) {
                        groups[date] = 1;
                    }else{
                        groups[date] = groups[date] + 1;
                    }
                    return groups;
                    }, {});

                let revenue_direct_by_date_sorted = this.sortObjectByKey(revenue_direct_by_date)
                let revenue_google_by_date_sorted = this.sortObjectByKey(revenue_google_by_date)
                let revenue_newsletter_by_date_sorted = this.sortObjectByKey(revenue_newsletter_by_date)
                let revenue_other_by_date_sorted = this.sortObjectByKey(revenue_other_by_date)
                let orders_by_date_sorted = this.sortObjectByKey(orders_by_date)

                let graph_data_revenue_direct = Object.keys(revenue_direct_by_date_sorted).map(function(prop) {
                    return [new Date(prop).getTime(), Math.round(revenue_direct_by_date_sorted[prop]*100)/100];
                });
                let graph_data_revenue_google = Object.keys(revenue_google_by_date_sorted).map(function(prop) {
                    return [new Date(prop).getTime(), Math.round(revenue_google_by_date_sorted[prop]*100)/100];
                });
                let graph_data_revenue_newsletter = Object.keys(revenue_newsletter_by_date_sorted).map(function(prop) {
                    return [new Date(prop).getTime(), Math.round(revenue_newsletter_by_date_sorted[prop]*100)/100];
                });
                let graph_data_revenue_other = Object.keys(revenue_other_by_date_sorted).map(function(prop) {
                    return [new Date(prop).getTime(), Math.round(revenue_other_by_date_sorted[prop]*100)/100];
                });
                let graph_data_orders = Object.keys(orders_by_date_sorted).map(function(prop) {
                    return [new Date(prop).getTime(), orders_by_date_sorted[prop]];
                });
                // console.log(this.result.data)

                // Colors: https://www.color-hex.com/color-palette/700
                let revenue_direct_daily = {}
                revenue_direct_daily.color = '#88b6cb'
                revenue_direct_daily.name = 'Direkt'
                revenue_direct_daily.yAxis = 0
                revenue_direct_daily.data = graph_data_revenue_direct

                let revenue_google_daily = {}
                revenue_google_daily.color = '#f37735'
                revenue_google_daily.name = 'Google'
                revenue_google_daily.yAxis = 0
                revenue_google_daily.data = graph_data_revenue_google

                let revenue_newsletter_daily = {}
                revenue_newsletter_daily.color = '#00b159'
                revenue_newsletter_daily.name = 'Newsletter'
                revenue_newsletter_daily.yAxis = 0
                revenue_newsletter_daily.data = graph_data_revenue_newsletter

                let revenue_other_daily = {}
                revenue_other_daily.color = '#ffc425'
                revenue_other_daily.name = 'Sonstige'
                revenue_other_daily.yAxis = 0
                revenue_other_daily.data = graph_data_revenue_other

                let orders_daily = {}
                orders_daily.color = 'rgb(56 95 115)'
                orders_daily.name = 'Bestellungen'
                orders_daily.yAxis = 1
                orders_daily.type = 'lollipop'
                orders_daily.data = graph_data_orders
                orders_daily.tooltip = {
                    pointFormat: '<b>{series.name}:</b> {point.y}<br>',
                }
                
                // x4rK25

                this.chartOptions.series = []
                this.chartOptions.series.push(revenue_direct_daily)
                this.chartOptions.series.push(revenue_google_daily)
                this.chartOptions.series.push(revenue_newsletter_daily)
                this.chartOptions.series.push(revenue_other_daily)
                this.chartOptions.series.push(orders_daily)
            },
            sortObjectByKey: function (object){
                return Object.keys(object).sort().reduce(
                    (obj, key) => { 
                        obj[key] = object[key]; 
                        return obj;
                    },{});
            },
            changeRange: function () {
                this.dates.sort();
                this.from = this.dates[0];
                this.to = this.dates[1];
                this.fetch();
                this.menu = false;
            },
            numberOfDays(from, to) {
              let start = moment(from, "YYYY-MM-DD");
              let end = moment(to, "YYYY-MM-DD");
              return moment.duration(end.diff(start)).asDays();
            },
        },
        created() {
            this.fetch();
            this.dates = [this.from, this.to]
            this.$vuetify.theme.dark = false
        },
        computed: {
            dateRangeText() {
                return this.dates.join(' - ')
            },
        },
    }
</script>
