import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import store from '../store/index';
import {RepositoryFactory} from '@/repositories/djangoBackend/RepositoryFactory';

const ConfigRepository = RepositoryFactory.get('config');
const ArticleCacheRepository = RepositoryFactory.get('articleCache');

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        status: '',
        access: localStorage.getItem('clvIntranetAccess') || '',
        refresh: localStorage.getItem('clvIntranetRefresh') || '',
        username: localStorage.getItem('clvIntranetUsername') || '',
        config:  JSON.parse(localStorage.getItem('clvIntranetConfigs')) || '',
        articleCache:  JSON.parse(localStorage.getItem('clvArticleCache')) || '',
        snackbar: {
            snack: '',
        }
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_error(state) {
            state.status = 'error';
        },
        auth_success(state, payload) {
            state.status = 'success';
            state.access = payload.access;
            state.refresh = payload.refresh;
            state.username = payload.username;
        },
        refresh_error(state) {
            state.status = 'error';
        },
        refresh_request(state) {
            state.status = 'loading';
        },
        refresh_success(state, access) {
            state.status = 'success';
            state.access = access;
        },
        config_success(state, data) {
            state.config = data;
        },
        article_cache_update(state, data) {
            state.config = data;
        },
        logout(state) {
            state.status = '';
            state.access = '';
            state.refresh = '';
            state.username = '';
            state.config = '';
        },
        setSnack (state, payload) {
            state.snackbar.snack = payload.message;
            state.snackbar.datetime = payload.datetime;
        },
    },
    actions: {
        login({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({url: 'https://api.clv.de/api/token/', data: user, method: 'POST'})
                    .then(resp => {
                        const access = resp.data.access;
                        const refresh = resp.data.refresh;
                        localStorage.setItem('clvIntranetAccess', access);
                        localStorage.setItem('clvIntranetRefresh', refresh);
                        localStorage.setItem('clvIntranetUsername', user.username);
                        axios.defaults.headers.common['Authorization'] = access;
                        commit('auth_success', {access: access, refresh: refresh, username: user.username});
                        dispatch('getArticleCache');
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('auth_error');
                        localStorage.removeItem('clvIntranetAccess');
                        localStorage.removeItem('clvIntranetRefresh');
                        localStorage.removeItem('clvIntranetUsername');
                        reject(err)
                    })
            })
        },
        getConfig({commit}) {
            // commit('config_request');
            ConfigRepository.get()
                .then(resp => {
                    let configs = {};
                    for (let i = 0; i < resp.data.length; i++) {
                        if (!configs[resp.data[i].name]) {
                            configs[resp.data[i].name] = [];
                        }
                        configs[resp.data[i].name] = resp.data[i].value;
                    }
                    localStorage.setItem('clvIntranetConfigs', JSON.stringify(configs));
                    commit('config_success', configs);
                })
                .catch(err => {
                    localStorage.removeItem('clvIntranetConfigs');
                    throw err;
                })
        },
        getArticleCache({commit}) {
            ArticleCacheRepository.get()
                .then(resp => {
                    localStorage.setItem('clvArticleCache', JSON.stringify(resp.data));
                    commit('article_cache_update', resp.data);
                })
                .catch(err => {
                    localStorage.removeItem('clvArticleCache');
                    throw err;
                })
        },
        refresh({commit}) {
            return new Promise((resolve, reject) => {
                commit('refresh_request');
                let data = {refresh: store.state.refresh};
                axios({url: 'https://api.clv.de/api/token/refresh/', data, method: 'POST'})
                    .then(resp => {
                        const access = resp.data.access;
                        localStorage.setItem('clvIntranetAccess', access);
                        commit('refresh_success', access);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('refresh_error');
                        localStorage.removeItem('clvIntranetAccess');
                        localStorage.removeItem('clvIntranetRefresh');
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('clvIntranetAccess');
                localStorage.removeItem('clvIntranetRefresh');
                localStorage.removeItem('clvIntranetUsername');
                localStorage.removeItem('clvIntranetConfigs');
                localStorage.removeItem('clvArticleCache');
                delete axios.defaults.headers.common['Authorization'];
                resolve()
            })
        },
        setMessage({commit}, message) {
            let currentDate = new Date();
            commit('setSnack', {datetime: currentDate, message: message});
        },
    },
    modules: {},
    getters: {
        isLoggedIn: state => !!state.access,
        authStatus: state => state.status,
        lastMessage: state => state.snackbar.datetime,
    },
})
