<template>
  <v-app id="inspire">

    <Menues/>

    <v-main>
      <div class="text-left">
        <v-sheet color="grey lighten-2" class="py-5 px-5"><h3>Übersicht</h3></v-sheet>
      </div>
      <v-container fluid class="px-5">

        <v-row dense>
          <v-col cols="12" sm="4" md="3" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">
                <v-icon left>mdi-download</v-icon>
                Download Logs
              </v-card-title>

              <v-card-subtitle>
                Auswertungen der Downloads im Shop.
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="light" text to="/downloads">
                  Öffnen
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="3" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">
                <v-icon left>mdi-book-open-variant</v-icon>
                Kataloge
              </v-card-title>

              <v-card-subtitle>
                Erstellung von Händler Katalogen.
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="light" text to="/catalogs">
                  Öffnen
                </v-btn>

              </v-card-actions>

            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="3" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">
                <v-icon left>mdi-tag-multiple</v-icon>
                Aktionen
              </v-card-title>
              <v-card-subtitle>
                Übersicht der Bestellungen über die Aktionsseiten.
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="light" text to="/promotions/orders">
                  Öffnen
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="3" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">
                <v-icon left>mdi-application-cog</v-icon>
                CLeVer Admin
              </v-card-title>
              <v-card-subtitle>
                Administration der CLeVer Daten und Kunden.
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="light" text to="/clever">
                  Öffnen
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="3" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">
                <v-icon left>mdi-email-multiple-outline</v-icon>
                Newsletter
              </v-card-title>
              <v-card-subtitle>
                Versand und Auswertung von Newslettern.
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="light" text to="/newsletters">
                  Öffnen
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" md="3" class="d-flex flex-column">
            <v-card color="#385F73" dark class="flex d-flex flex-column">
              <v-card-title class="headline">
                <v-icon left>mdi-download</v-icon>
                XML Katalog
              </v-card-title>
              <v-card-subtitle>
                Download des XML-Katalogs mit Script. Cache und Feed Update notwendig.
              </v-card-subtitle>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="light"
                    text
                    @click="downloadXML()"
                    :loading="downloadingXML"
                >
                  Download
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menues from '@/components/layout/Menues'
import {RepositoryFactory} from "@/repositories/djangoBackend/RepositoryFactory";

const CatalogsRepository = RepositoryFactory.get('catalogs');

export default {
  name: "Dashboard",
  components: {
    Menues,
  },
  props: {
    source: String,
  },
  data() {
    return {
      downloadingXML: false,
    }
  },
  created() {
    this.$vuetify.theme.dark = false
  },
  methods: {
    async downloadXML() {
      this.downloadingXML = true
      const response = await CatalogsRepository.getFullCatalogXML()
      await this.downloadBlob(response.data, 'HaendlerKatalog.zip')
      this.downloadingXML = false
    },
    downloadBlob(blob, filename) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
          new Blob([blob])
      );
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
  }
}
</script>
